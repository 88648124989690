import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {getMyMemberships} from "../../redux/features/settingsSlice";
import planSVG from "../../assets/svgs/plan.svg"
import PromocodeModal from "../PromocodeModal";
import {Insurance} from "../Insurance";

function DashboardHeader({withInsurance = false}) {
    const [showNotifications, setShowNotifications] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {memberships} = useSelector(state => state.settings);
    const [currentPlan, setCurrentPlan] = useState(null);

    useEffect(() => {
        dispatch(getMyMemberships());
    }, [dispatch]);

    useEffect(() => {
        setCurrentPlan(memberships?.filter(membership => membership.hub_code === localStorage.getItem('currentBranchCode')))
    }, [memberships, localStorage.getItem('currentBranchCode')])


    return (
        <div className="New-header-tops-flex">
            <div className="part-one-header-flex">
                <button className="but-puls" type="button" disabled={true} style={{cursor: "initial"}}>
                    <i className="bx bx-plus mx-1"></i>
                    <span>{currentPlan ? currentPlan[0]?.plan : ''}</span>
                </button>

            <div className="part-one-header-flex part-two-header-flex" style={{"background": "#352a50"}}>
                <button className="but-Number font-weight-bold" disabled={true} style={{cursor: "initial"}}>
                    <span>UM1A6600</span>
                </button>
                <button className="but-none-bg font-weight-normal ml-4" disabled={true} style={{cursor: "initial"}}>
                    {/*<img src={planSVG} alt="plan" width={"30px"} className="ml-2 mr-4"/>*/}
                    {/*<i className="fas fa-plane-departure bg-transparent mx-2 text-light"></i>*/}
                    <span style={{fontSize: "1rem"}}><Trans
                        i18nKey="4Days12Hours"
                        components={{
                            s: <strong/>,
                            s2: <strong/>,
                            s3: <span id="separator"/>
                        }}
                    /><span style={{fontWeight:"600"}}>{t("descInbox2")}</span></span>
                </button>
            </div>


            </div>

            {/* <div id="NewNotofication" className="New-Notofication">
      <i
        className="bx bx-bell"
        onClick={() => setShowNotifications(!showNotifications)}
      ></i>
    </div> */}


            {withInsurance&& <Insurance />}
        </div>
    )
}

export default DashboardHeader