import React from "react";
import warningIcon from "../../../assets/images/Warning-icon.svg";
import { useTranslation } from "react-i18next";

function WarningAddress() {
  const { t } = useTranslation();

  return (
    <div className={"mt-5 ml-3"}>
      <div className="woring-flex">
        <div className="warning-image">!</div>
        <p>
          {t("warningShippingAddress")}
        </p>
      </div>

      <div className="woring-flex">
          <div className="warning-image">!</div>
        <p>
        {t("warningShippingAddressSecond")}
        </p>
      </div>
    </div>
  );
}

export default WarningAddress;
