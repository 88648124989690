import React, {useEffect, useState} from "react";
import logo from "../../assets/images/logo-dashboard.svg";
import {NavLink, useLocation} from "react-router-dom";
import axios from "axios";
import {LOGOUT_API, SRC_IMG_API,} from "../../apis/apis";
import {useDispatch, useSelector} from "react-redux";
import {getMyBranch} from "../../redux/features/myBranchSlice";
import {useTranslation} from "react-i18next";
import {getPackagesInbox} from "../../redux/features/inboxSlice";

import {getBranchs} from "../../redux/features/branchsSlice";
import {getPackagesOutbox} from "../../redux/features/outboxSlice";
import {getPackagesArchive} from "../../redux/features/ArchiveSlice";
import "./selected.css"
import {fetchInsurance} from "../../redux/features/InsuranceSlice";

function Sidebar() {
    const {t} = useTranslation();
    const [showDropDown, setShowDropDown] = useState(false)
    const dispatch = useDispatch();
    const [code, setCode] = useState("");
    const location = useLocation();

    const branchs = useSelector((state) => state.branchs.branchs);
    useEffect(() => {


        dispatch(getBranchs());
        dispatch(
            getPackagesInbox({
                country_code: localStorage.getItem("currentBranchCode"),
                status: null,
            })
        );
    }, []);


    useEffect(() => {
        if (location.pathname.includes('settings')) {

            setShowDropDown(true);
        }
    }, [location]);

    const handleClick = async (code) => {
        setCode(code);
        try {
            localStorage.setItem("currentBranchCode", code);

            dispatch(getMyBranch());
            dispatch(getPackagesInbox({country_code: code, status: null}));
            dispatch(getPackagesArchive({country_code: code}));
            dispatch(
                getPackagesOutbox({
                    country_code: code,
                })
            );

            dispatch(fetchInsurance({country_code: localStorage.getItem("currentBranchCode")}))



        } catch (error) {
            console.log(error);
        }
    };
    const logOut = async () => {
        try {
            await axios.post(
                LOGOUT_API,
                {},
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("currentBranchCode");
            window.location.pathname = "/signin";
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className={`sidebar active`} id={"sider"} style={{overflow: "auto"}}>
                <div className="logo_content">
                    <div className="logo">
                        <img src={logo} alt={t('logo')} loading="lazy"/>
                    </div>
                </div>
                <ul className="nav_list text-center">
                    <li>
                        <NavLink to="/dashboard/inbox">
                            <p className={"py-0 m-0"}>

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.17004 7.43994L12 12.5499L20.77 7.46991" stroke="#292D32"
                                          strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 21.6099V12.5399" stroke="#292D32" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M9.92999 2.48L4.59 5.45003C3.38 6.12003 2.39001 7.80001 2.39001 9.18001V14.83C2.39001 16.21 3.38 17.89 4.59 18.56L9.92999 21.53C11.07 22.16 12.94 22.16 14.08 21.53L19.42 18.56C20.63 17.89 21.62 16.21 21.62 14.83V9.18001C21.62 7.80001 20.63 6.12003 19.42 5.45003L14.08 2.48C12.93 1.84 11.07 1.84 9.92999 2.48Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M17 13.24V9.58002L7.51001 4.09998" stroke="#292D32" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                <span className="links_name">{t("My Suite")}</span>
                            </p>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/dashboard/outbox">
                            <p className={"py-0 m-0"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.86002 19L11.54 17.58C11.79 17.37 12.21 17.37 12.47 17.58L14.14 19C14.53 19.2 15.01 19 15.15 18.58L15.47 17.62C15.55 17.39 15.47 17.05 15.3 16.88L13.66 15.23C13.54 15.11 13.45 14.88 13.45 14.72V12.87C13.45 12.45 13.76 12.25 14.15 12.41L17.5 13.85C18.05 14.09 18.51 13.79 18.51 13.19V12.26C18.51 11.78 18.15 11.22 17.7 11.03L13.76 9.32999C13.59 9.25999 13.46 9.04999 13.46 8.86999V6.79999C13.46 6.11999 12.96 5.31999 12.36 5.00999C12.14 4.89999 11.89 4.89999 11.67 5.00999C11.06 5.30999 10.57 6.11999 10.57 6.79999V8.86999C10.57 9.04999 10.43 9.25999 10.27 9.32999L6.33002 11.03C5.89002 11.22 5.52002 11.78 5.52002 12.26V13.19C5.52002 13.79 5.97002 14.09 6.53002 13.85L9.88002 12.41C10.26 12.24 10.58 12.45 10.58 12.87V14.72C10.58 14.89 10.48 15.12 10.37 15.23L8.70002 16.87C8.53002 17.04 8.45002 17.38 8.53002 17.61L8.85002 18.57C8.99002 19 9.46002 19.2 9.86002 19Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                    <span className="links_name">{t("Shipments")}</span>
                            </p>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/dashboard/archive">
                            <p className={"py-0 m-0"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M2 13H5.76C6.52 13 7.21 13.43 7.55 14.11L8.44 15.9C9 17 10 17 10.24 17H13.77C14.53 17 15.22 16.57 15.56 15.89L16.45 14.1C16.79 13.42 17.48 12.99 18.24 12.99H21.98"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M10.34 7H13.67" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M9.5 10H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>


                                <span className="links_name">{t("Archive")}</span>
                            </p>
                        </NavLink>
                        <span className="tooltip">{t("Archive")}</span>
                    </li>
                    <li>
                        <NavLink to="/dashboard/shipping-address">
                            <p className={"py-0 m-0"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 2V12C15 13.1 14.1 14 13 14H2V6C2 3.79 3.79 2 6 2H15Z" stroke="#292D32"
                                          strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M22 14V17C22 18.66 20.66 20 19 20H18C18 18.9 17.1 18 16 18C14.9 18 14 18.9 14 20H10C10 18.9 9.1 18 8 18C6.9 18 6 18.9 6 20H5C3.34 20 2 18.66 2 17V14H13C14.1 14 15 13.1 15 12V5H16.84C17.56 5 18.22 5.39001 18.58 6.01001L20.29 9H19C18.45 9 18 9.45 18 10V13C18 13.55 18.45 14 19 14H22Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z"
                                          stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>


                                <span className="links_name">{t("Address")}</span>
                            </p>
                        </NavLink>
                        <span className="tooltip">{t("Address")}</span>
                    </li>
                    {/*<li>*/}
                    {/*  <NavLink to="/dashboard/delivery-address">*/}
                    <p className={"py-0 m-0"}>{/*
                    <i className="bx bx-home"></i>*/}

                        {/*    <span className="links_name">{t("")}</span>*/}
                    </p>
                    {/*  </NavLink>*/}
                    {/*  <span className="tooltip">{t("")}</span>*/}
                    {/*</li>*/}
                    <li>
                        <NavLink to="/dashboard/delivery-requests">
                            <p className={"py-0 m-0"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 13.4299C13.7231 13.4299 15.12 12.0331 15.12 10.3099C15.12 8.58681 13.7231 7.18994 12 7.18994C10.2769 7.18994 8.88 8.58681 8.88 10.3099C8.88 12.0331 10.2769 13.4299 12 13.4299Z"
                                        stroke="#292D32" strokeWidth="1.5"/>
                                    <path
                                        d="M3.62001 8.49C5.59001 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39001 20.54C5.63001 17.88 2.47001 13.57 3.62001 8.49Z"
                                        stroke="#292D32" strokeWidth="1.5"/>
                                </svg>


                                <span className="links_name">{t("Home Delivery")}</span>
                            </p>
                        </NavLink>
                        <span className="tooltip">{t("Home Delivery")}</span>
                    </li>
                    {/* <li>
            <a href="home-delivery.html">
              <i className="bx bxs-truck"></i>

              <span className="links_name">Home Delivery</span>
            </a>
            <span className="tooltip">Home Delivery</span>
          </li> */}
                    <li onClick={() => setShowDropDown(old => !old)}>
                        <a href="#">
                            <p className={"py-0 m-0"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M15.5699 18.5001V14.6001" stroke="#292D32" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15.5699 7.45V5.5" stroke="#292D32" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M15.57 12.65C17.0059 12.65 18.17 11.4859 18.17 10.05C18.17 8.61401 17.0059 7.44995 15.57 7.44995C14.134 7.44995 12.97 8.61401 12.97 10.05C12.97 11.4859 14.134 12.65 15.57 12.65Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.43005 18.5V16.55" stroke="#292D32" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.43005 9.4V5.5" stroke="#292D32" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M8.42996 16.5501C9.8659 16.5501 11.03 15.386 11.03 13.9501C11.03 12.5142 9.8659 11.3501 8.42996 11.3501C6.99402 11.3501 5.82996 12.5142 5.82996 13.9501C5.82996 15.386 6.99402 16.5501 8.42996 16.5501Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                <span className="links_name">{t("settings")}</span>
                            </p>

                            <span className="tooltip">{t("settings")}</span>
                        </a>

                    </li>
                    <ul className={`${showDropDown ? 'd-block' : 'd-none'}  dropmenu pt-0 mt-0`}>
                        <li><NavLink to="/dashboard/settings/account">{t('accountInformation')}</NavLink></li>
                        <li><NavLink to="/dashboard/settings/memberships">{t('memberships')}</NavLink></li>
                        <li><NavLink to="/dashboard/settings/password">{t('password')}</NavLink></li>
                        <li><NavLink to="/dashboard/settings/wallet">{t('Wallet')}</NavLink></li>
                    </ul>
                </ul>
                <div className="border-box-country border-0">
                    {branchs.map((item) => (
                        <div
                            className={`list-city link-dark my-1 ${
                                item.code === localStorage.getItem("currentBranchCode") &&
                                "list-act"
                            }`}
                            onClick={() => handleClick(item.code)}
                            role="button"
                            key={item.id}
                        >
                            <img
                                src={`${SRC_IMG_API}/${item.icon}`}
                                style={{objectFit: "contain"}}
                                alt={t('icon')}
                                width={30}
                                height={30}
                                loading="lazy"
                            />
                            <p style={{paddingLeft: 0}}>{item.name}</p>
                        </div>
                    ))}
                </div>
                <div className="border-box-country border-0">
                    <ul className="nav-list mt-3">
                        <li onClick={logOut} role="button"
                            className={"d-flex align-items-center justify-content-start logoutItem"}
                            style={{paddingLeft: "0.8rem"}}>
                            {/* <button >

            </button> */}
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.56006 9.38L4.00006 11.94L6.56006 14.5" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.24 11.9399L4.06999 11.9399" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.24 4C16.66 4 20.24 7 20.24 12C20.24 17 16.66 20 12.24 20" stroke="#292D32"
                                      strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>


                            <span className="links_name" style={{marginLeft: '18px'}}>{t("logOut")}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="topmune">
                <nav className="navbar navbar-expand-lg navbar-dark  ">
                    <NavLink className="navbar-brand" to="/">
                        <img src={logo} alt={t('logo')} loading="lazy"/>
                    </NavLink>
                    <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label={t('toggleNavigation')}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard/inbox">
                                    {t("My Suite")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard/outbox">
                                    {t("Shipments")}
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard/archive">
                                    {t("archive")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard/delivery-requests">
                                    {t("Address")}
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard/shipping-address">
                                    {t("Home Delivery")}
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard/settings">
                                    {t("settings")}
                                </NavLink>
                            </li>
                            {branchs.map((item) => (
                                <li
                                    className={`list-city link-dark my-3  ${
                                        item.code === localStorage.getItem("currentBranchCode") &&
                                        "list-act"
                                    }`}
                                    onClick={() => handleClick(item.code)}
                                    role="button"
                                    key={item.id}
                                >
                                    <img
                                        style={{objectFit: "contain"}}
                                        src={`${SRC_IMG_API}/${item.icon}`}
                                        alt={t('icon')}
                                        width={30}
                                        height={30}
                                        loading="lazy"
                                    />
                                    <p>{item.name}</p>
                                </li>
                            ))}
                            <li className="nav-item">
                                <NavLink to="/signin" className="nav-link" onClick={logOut}>
                                    {t("logOut")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Sidebar;
