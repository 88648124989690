import React, {useState} from "react";
import boxImg from "../../../assets/images/img-box1.svg";
import {Link} from "react-router-dom";
import weightImg from "../../../assets/svgs/weight.svg";
import planImg from "../../../assets/svgs/plan.svg";
import truckImg from "../../../assets/svgs/truck.svg";
import truckWhiteImg from "../../../assets/svgs/truckWhite.svg";
import {Trans, useTranslation} from "react-i18next";

function PackagesList({item, i}) {
    const [showTable, setShowTable] = useState(false);
    const [showTracking, setShowTracking] = useState(false);
    const {t} = useTranslation();

    return (
        <section className="new-content-suite mt-5 package" key={i}>
            <div className="content-suite-header-content-flex" style={{zIndex: 100000000000000000}}>
                <div className="box-with-shadow"></div>
                {/*</article>*/}
                <article className="part-left-title-content-suite position-relative justify-content-sm-center">
                    <div style={{marginLeft: "15px"}}>
                        <img src={boxImg} alt={t('boxImg')} loading="lazy"/>
                    </div>
                    <article>
                        <h5 className={"mb-0"}>
                            <Trans
                                i18nKey="shipmentId"
                            /> <span>{item?.wasil_user_track_id}</span>
                        </h5>
                        <p className={"mb-0"}>{item?.date}</p>
                    </article>

                </article>


                <article className="part-right-title-content-suite position-relative justify-content-sm-center">


                    <div className={"text-right"}>

                        <p>{t('shipmentStatus')}</p>
                        <h5>{item.status.toLowerCase() === 'home delivery request' ? t('HOME DELIVERY REQUESTED') : item.status.toLowerCase() === 'out for home'? t('OUT FOR HOME DELIVERY'):t(item.status.toUpperCase())}</h5>
                    </div>
                    <div className="prat-tight-iocn">
                        {item?.packages[0]?.items[0]?.is_dangerous === 1 && (
                            <div className="box-part-right-icon ic-right-bg-one">
                                <span className="bx bxs-hot" style={{fontSize: "2rem"}}></span>
                            </div>
                        )}

                        {item?.status.toLowerCase() === 'ready for delivery' || item?.status.toLowerCase() === 'delivered' ?
                            <div className={`box-part-right-icon ic-right-bg-four`} style={{
                                background: item?.status.toLowerCase() === 'delivered' && '#020202'
                            }}>
                                <span className="bx bx-check" style={{fontSize: "2rem"}}></span>
                            </div>
                            : item?.status.toLowerCase() === 'home delivery request' || item?.status.toLowerCase() === 'home delivery requested' ?
                                <div className="box-part-right-icon"
                                     style={{padding: '0.5rem', border: "2px solid #6612d3"}}>
                                    <img src={truckImg} alt={t('plan')} width="25"/>
                                </div>
                                :item?.status.toLowerCase() === 'out for home' || item?.status.toLowerCase() === 'out for home delivery' ?
                                    <div className="box-part-right-icon ic-right-bg-tow"
                                         style={{padding: '0.5rem'}}>
                                        <img src={truckWhiteImg} alt={t('plan')} width="25"/>
                                    </div>:
                                <div className="box-part-right-icon py-3 px-2 ic-right-bg-tow">
                                    <img src={planImg} alt={t('plan')} width="25"/>
                                </div>
                        }
                    </div>


                </article>

            </div>
            <div>
                <div
                    id="newTable"
                    className="newTable"
                    style={{
                        position: 'relative',
                        zIndex: 1,
                        display: `${showTable && item.id === item.id ? "block" : "none"}`,
                    }}
                >
                    <div className="box-with-shadow"></div>
                    <div className="table-responsive items-table" style={{zIndex: 100000, position: "relative"}}>

                        {item.packages.map(pack => <>
                                <table className="table table-striped">
                                    <thead>
                                    <tr className={"pt-2"}>
                                        <th className={"w-50"}><span>{pack.sender}</span><strong
                                            className={"mx-1"}> | </strong>{pack.track_number}</th>
                                        <th style={{textAlign: 'center'}}
                                            className={`${localStorage.getItem("currentBranchCode") !== "usa" && 'last-col td'}`}>{t("quantity")}</th>
                                        {localStorage.getItem("currentBranchCode") === "usa" && (
                                            <>
                                                <th style={{textAlign: 'center'}}>{t('valuePerUnit')}</th>
                                                <th style={{textAlign: 'center'}} className={"last-col"}>{t('totalValue')}</th>
                                            </>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pack.items.map((items, i) => (
                                        <tr key={i}>
                                            <td data-label="Item Description"
                                                style={{paddingLeft: "46px"}}>{items.category}</td>
                                            <td style={{textAlign: "center"}} data-label="Quantity">{items.quantity}</td>
                                            {localStorage.getItem("currentBranchCode") === "usa" && (
                                                <>
                                                    <td style={{textAlign: "center"}}
                                                        data-label="Value per unit">$ {parseFloat(items.unit_value).toFixed(2)}</td>
                                                    <td style={{textAlign: "center"}}
                                                        data-label=" Total value"
                                                        className={"last-col td"}>$ {parseFloat(items.total_value).toFixed(2)}</td>
                                                </>
                                            )}
                                        </tr>

                                    ))}


                                    </tbody>
                                </table>
                                <Link
                                    to={`/dashboard/pictures/${pack.id}`}
                                    className="viewPicture btn" style={{margin: "10px 45px"}}
                                >
                                    {t("viewPictures")}
                                </Link>
                            </>
                        )

                        }

                    </div>
                </div>


                <div
                    id="newTable"
                    className="newTable"
                    style={{
                        display: `${showTracking ? "block" : "none"}`,
                        margin: 'auto',
                        position: "relative"
                    }}
                >
                    <div className="box-with-shadow"></div>

                    <div className="table-responsive items-table" style={{zIndex: 100000, position: "relative"}}>

                                    <table className="table table-striped">

                                        <tbody>
                        {
                            item.tracking?.map(trackStep => {
                                return (
                                        <tr className="active-tr">
                                            <td data-label="Item Description" className={"py-3"} style={{paddingLeft: "30px"}}>

                                                <span style={{color: "#6612d3", fontWeight: "bold"}}>{trackStep.title.toUpperCase()}</span>

                                                <p className={"mb-0 pb-0"}>{trackStep.track_date}</p>
                                            </td>
                                            <td
                                                className={"py-2"}
                                            style={{paddingLeft: "46px"}}>
                                                <p className="small m-0 p-0">{trackStep.description}</p>
                                                <p className={"mb-0 pb-0"}>{trackStep.city}, {trackStep.country}</p>
                                            </td>
                                            <td data-label="Item Description" style={{paddingLeft: "30px"}}>


                                            </td>

                                        </tr>
                                )
                            })
                        }

                                        </tbody>
                                    </table>
                    </div>
                </div>

            </div>
            <div className="card-edting-button-page">
                <div className="card-edting-part1 align-items-sm-start justify-content-sm-start">
                    <div className="part1-flex-weight-and-value">
                        <div className="part1-bg-icon">
                            <img src={weightImg} alt={"weight"} width={"20px"}/>
                        </div>
                        <div className="part1-cotnent">
                            <p>{t('weight')}</p>
                            <h5>
                                {item.total_weight} {t(item.weight_unit)}
                            </h5>
                        </div>
                    </div>

                    {parseInt(item.total_value) !== 0 &&
                        <div className="part1-flex-weight-and-value">
                            <div className="part1-bg-icon">
                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.2519 8.07812V10.9381L10.2419 10.5881C9.73187 10.4081 9.42188 10.2381 9.42188 9.36812C9.42188 8.65812 9.95188 8.07812 10.6019 8.07812H11.2519Z"
                                        fill="#fff"/>
                                    <path
                                        d="M14.58 14.6286C14.58 15.3386 14.05 15.9186 13.4 15.9186H12.75V13.0586L13.76 13.4086C14.27 13.5886 14.58 13.7586 14.58 14.6286Z"
                                        fill="#fff"/>
                                    <path
                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 13.92 8.25 13.58 8.67 13.58C9.08 13.58 9.42 13.92 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 10.08 15.75 10.42 15.33 10.42C14.92 10.42 14.58 10.08 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12Z"
                                        fill="#fff"/>
                                </svg>
                            </div>
                            <div className="part1-cotnent">
                                <p>{t('value')}</p>
                                <h5>{parseFloat(item.total_value).toFixed(2)} {t('usd')}</h5>
                            </div>

                        </div>
                    }

                    <div className="part1-flex-weight-and-value">
                        <div className="part1-bg-icon">
                            <svg width="20" height="20" viewBox="0 0 18 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.3498 0H6.64977C5.60977 0 4.75977 0.84 4.75977 1.88V2.82C4.75977 3.86 5.59977 4.7 6.63977 4.7H11.3498C12.3898 4.7 13.2298 3.86 13.2298 2.82V1.88C13.2398 0.84 12.3898 0 11.3498 0Z"
                                    fill="#fff"/>
                                <path
                                    d="M14.2391 2.81949C14.2391 4.40949 12.9391 5.70949 11.3491 5.70949H6.64906C5.05906 5.70949 3.75906 4.40949 3.75906 2.81949C3.75906 2.25949 3.15906 1.90949 2.65906 2.16949C1.24906 2.91949 0.289062 4.40949 0.289062 6.11949V15.5295C0.289062 17.9895 2.29906 19.9995 4.75906 19.9995H13.2391C15.6991 19.9995 17.7091 17.9895 17.7091 15.5295V6.11949C17.7091 4.40949 16.7491 2.91949 15.3391 2.16949C14.8391 1.90949 14.2391 2.25949 14.2391 2.81949ZM9.37906 14.9495H4.99906C4.58906 14.9495 4.24906 14.6095 4.24906 14.1995C4.24906 13.7895 4.58906 13.4495 4.99906 13.4495H9.37906C9.78906 13.4495 10.1291 13.7895 10.1291 14.1995C10.1291 14.6095 9.78906 14.9495 9.37906 14.9495ZM11.9991 10.9495H4.99906C4.58906 10.9495 4.24906 10.6095 4.24906 10.1995C4.24906 9.78949 4.58906 9.44949 4.99906 9.44949H11.9991C12.4091 9.44949 12.7491 9.78949 12.7491 10.1995C12.7491 10.6095 12.4091 10.9495 11.9991 10.9495Z"
                                    fill="#fff"/>
                            </svg>

                        </div>
                        <div className="part1-cotnent">
                            <p>{item?.packages?.length} {t('packages')}</p>
                            <h5>{item?.box_count} {t('boxes')}</h5>
                        </div>
                    </div>
                </div>

                <div className="card-edting-part2 flex-wrap" style={{gap: '0.5rem'}}>


                    <Link
                        to={item.invoice_link}
                        target="_blank"
                        className="btn viewPicture mb-3 mb-md-0"
                    >
                        {t('invoice')}
                    </Link>
                    {item.status.toLowerCase() !== "on the way" && <Link
                        to={item.inspection_link}
                        target="_blank"
                        className="btn EditPrice"
                    >
                        {t('inspectionForm')}
                    </Link>}


                    <button
                        type="button"
                        id="TogglesIcon"
                        className="btn EditPrice"
                        onClick={() => {
                            setShowTracking(!showTracking);
                            setShowTable(false);
                        }}
                    >
                        {t('tracking')}

                    </button>


                    <button
                        type="button"
                        id="TogglesIcon"
                        className={`bx ${
                            showTable ? "bx-chevron-up" : "bx-chevron-down"
                        } button-toggle-arrows`}
                        onClick={() => {
                            setShowTable(!showTable)
                            setShowTracking(false)
                        }}
                    ></button>

                </div>
            </div>


        </section>
    )
        ;
}

export default PackagesList;
